/* body {
  background-color: yellow !important;
} */

.plan-cat {
  cursor: pointer;
  margin-bottom: 30px;
}

.plan-selected {
  background-color: #4e8de9 !important;
  color: #fff !important;
}

.plan-cat:hover {
  background-color: #78a5e9;
}
