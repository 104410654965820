.landing-container {
  position: absolute;
  /* right: 1%; */
  top: 50%;
  left: 50%;
  /* margin: 0; */
  transform: translate(-50%, -50%);
  /* width: 45%; */
  min-height: 80vh;
  min-width: 50vw;
  z-index: 400;
}

.landing-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-button {
  width: 140px;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.landing-button:hover {
  background-color: #033576;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}
.secondary-landing-button:hover {
  background-color: #ebe050;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

@media only screen and (max-width: 768px) {
  .landing-container {
    min-width: 90vw !important;
  }
}
