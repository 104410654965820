/* .leaflet-top.leaflet-right {
  position: absolute;
  left: 30%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
} */

.geocoder-control-input {
  width: 105%;
  font-size: 15px;
  /* background-image: url(); */
}

.leaflet-top.leaflet-left input {
  padding: 10px;
  -webkit-text-fill-color: #1a1818;
  color: silver;
}

.buttons-container {
  position: absolute;
  right: 1%;
  top: 5%;
  /* margin: 0; */
  transform: translate(-50%, -50%);
  width: 45%;
  z-index: 400;
  /* padding: 10px; */
  /* display: flex;
  flex-direction: row;
  justify-content: space-around; */
}

.buttons-container .menu-btn {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 32px;
  color: rgb(60, 64, 67);
  cursor: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 32px;
  margin: 8px 0;
  padding: 0 12px;
  border: none;
  background: #fff;
  box-shadow: 0 1px 2px rgba(96, 143, 179, 0.3),
    0 1px 3px 1px rgba(43, 113, 167, 0.15);
}

.settings-pane {
  position: absolute;
  right: 2%;
  top: 7%;
  /* margin: 0; */
  transform: translate(-50%, -50%);
  /* width: 45%; */
  z-index: 401;
  /* padding: 10px; */
  /* display: flex;
  flex-direction: row;
  justify-content: space-around; */
}

.menu-pane {
  width: 35%;
  position: absolute;
  z-index: 400;
  top: 10%;
  right: 0;
  transform: translate(0%, 5%);
}

.menu-pane .menu-ribbon {
  min-width: 80px;
  max-width: 80px;
  height: 75px;
  display: flex;
  z-index: 400;
  flex-direction: column;
  align-items: flex-start;
  background-color: aliceblue;
  padding: 5px;
  text-decoration: none;
  color: inherit;
}
.menu-pane .menu-ribbon:hover {
  background-color: rgb(188, 188, 212);
}

.menu-pane .menu-ribbon span {
  white-space: nowrap;
  overflow: hidden;
  z-index: 400;
  text-overflow: ellipsis;
  max-width: 80px;
  margin-top: 10px;
  font-size: 12px;
}

.buttons-container a {
  text-decoration: none;
}

.business-card a {
  text-decoration: none;
}
.d-flex.to-be-hidden {
  display: none !important;
}

/* @media only screen and (max-width: 600px) {
  .buttons-container > * {
    width: auto !important;
  }
  .buttons-container {
    width: 100%;
  }
  .leaflet-top.leaflet-right {
    left: 50%;
    margin-left: 15px;
  }
} */
@media only screen and (max-width: 768px) {
  .buttons-container > * {
    display: none !important;
  }
  .d-flex.to-be-hidden.to-be-hidden {
    display: flex !important;
  }
  .menu-pane {
    position: absolute;
    width: 98%;
    z-index: 400;
    top: 8%;
    right: 0;
    transform: translate(0%, 5%);
  }
  .settings-pane {
    top: 10% !important;
    right: -8% !important;
  }
}
