.App {
  font-family: sans-serif;
}

/* html,
body,
#root,
.App,
#mapId {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
} */

.logged-in-link {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 32px;
  color: rgb(60, 64, 67);
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  /* height: 32px; */
  margin: 8px;
  padding: 4px !important;
  border: none;
  background: #fff;
  box-shadow: 0 1px 2px rgba(96, 143, 179, 0.3),
    0 1px 3px 1px rgba(43, 113, 167, 0.15);
}

.content-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.overlaid {
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(78, 95, 110, 0.3);
}
.mr-3 {
  margin-right: 20px;
}
.fixed-view {
  max-height: 50vh;
  overflow-y: scroll;
}
.disease-row {
  cursor: pointer;
}
.disease-row:hover {
  background-color: #b6effb;
  color: #055160;
}
@media only screen and (max-width: 768px) {
  .content-centered {
    position: absolute;
    width: 100% !important;
    top: 65%;
    /* -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  }
  .settings-pane {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .settings-pane {
    display: inherit !important;
  }
  .fixed-bottom {
    display: none !important;
  }
}
@media not print {
  .container-div {
    /* Your styles for the div here */

    width: 100%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media print {
  .not-printable {
    /* visibility: hidden; */
    display: none !important;
  }
}

.Toastify__toast-body {
  flex: 2 !important;
}
.Toastify__close-button {
  flex: 0.2 !important;
}
