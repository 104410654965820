/* stripe.css */
.container {
  margin-top: 50px;
}

.card-header {
  background-color: #f8f9fa;
}

.card-title {
  font-size: 1.5rem;
}

.list-group-item {
  font-size: 1rem;
  color: #333;
}

.form-group {
  margin-top: 20px;
}

button {
  margin-top: 20px;
  width: 100%;
}
.bg-logo {
  background-color: #f8f8fa;
}
